import React, { useEffect, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Button, VerticalGroup } from '@grafana/ui';
import axios from 'axios';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  let pluginId = useRef(0);
  useEffect(() => {
    axios.get('api/datasources/id/MQTT2').then((res) => {
      pluginId.current = res.data.id;
    });
  }, []);

  //useref

  const sendData = (topic: string, command: string) => {
    let interpolatedUrl = 'api/datasources/' + pluginId.current + '/resources/updatemqttdoc';
    //console.log('sending to: ' + interpolatedUrl);

    axios
      .post(interpolatedUrl, {
        Topic: topic,
        Payload: generatePayload(command),
      })
      .then((res) => {
        //console.log(res);
      });
  };

  const generatePayload = (command: string) => {
    let payload: any;
    switch (command) {
      case 'red':
        payload = { color: 1 };
        break;
      case 'yellow':
        payload = { color: 2 };
        break;
      case 'lime':
        payload = { color: 3 };
        break;
      case 'blue':
        payload = { color: 4 };
        break;
      case 'white':
        payload = { color: 5 };
        break;
      case 'purple':
        payload = { color: 6 };
        break;
      case 'cyan':
        payload = { color: 7 };
        break;
      case 'blinkOn':
        payload = { blink: 1 };
        break;
      case 'blinkOff':
        payload = { blink: 0 };
        break;
      default:
        payload = { color: 1 };
        break;
    }
    return payload;
  };

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <VerticalGroup>
        <Button
          className="red"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'red')}
          style={{ backgroundColor: 'red', color: 'black' }}
        >
          Red
        </Button>
        <Button
          className="yellow"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'yellow')}
          style={{ backgroundColor: 'yellow', color: 'black' }}
        >
          Yellow
        </Button>
        <Button
          className="lime"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'lime')}
          style={{ backgroundColor: 'lime', color: 'black' }}
        >
          Lime
        </Button>
        <Button
          className="blue"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'blue')}
          style={{ backgroundColor: 'blue', color: 'black' }}
        >
          Blue
        </Button>
        <Button
          className="cyan"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'cyan')}
          style={{ backgroundColor: 'cyan', color: 'black' }}
        >
          Cyan
        </Button>
        <Button
          className="purple"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'purple')}
          style={{ backgroundColor: 'purple', color: 'black' }}
        >
          Purple
        </Button>
        <Button
          className="white"
          size="lg"
          icon="bolt"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'white')}
          style={{ backgroundColor: 'white', color: 'black' }}
        >
          White
        </Button>
        <br></br>
        <br></br>
        <Button
          className="blinkOn"
          size="lg"
          icon="eye"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'blinkOn')}
          style={{ backgroundColor: 'grey', color: 'black' }}
        >
          Blink
        </Button>
        <Button
          className="BlinkOff"
          size="lg"
          icon="eye-slash"
          fullWidth={true}
          onClick={() => sendData('dt/pdn/plcdemo/command', 'blinkOff')}
          style={{ backgroundColor: 'grey', color: 'black' }}
        >
          Steady
        </Button>
      </VerticalGroup>
    </div>
  );
};
